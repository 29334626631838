import React from "react"

const SPEC_ALT_COLORS = ['text-white', 'text-yellow-300', 'text-pink-300'];
const SpecAlt = ({children, alt_color_indx}) => {
	let alt_styles;

	if (!alt_color_indx) {
		alt_styles = SPEC_ALT_COLORS[1];
	} else {
		alt_styles = SPEC_ALT_COLORS[alt_color_indx] || SPEC_ALT_COLORS[0];
	}

	return <span className={alt_styles}>{children}</span>;
};

export default SpecAlt
