import React from "react"
import SpecAlt from "../components/SpecAlt"
import SpecSep from "../components/SpecSep"

export const withoutNotes = (arr) => {
    return arr.filter((x) => !x.startsWith('*'));
  };

export const notesOnly = (arr) => {
  return arr.filter((x) => x.startsWith('*'));
};

export const getModelYears = (model_years) => {
  return withoutNotes(model_years).join(', ');
};

export const getFinishYears = (available_years, model_years) => {
  let years_txt = '';
  const years = withoutNotes(available_years);
  const last = years.length - 1;

  years.forEach((x, indx) => {
      if (x === 'ALL') {
          years_txt += withoutNotes(model_years).join(', ');
      } else {
          years_txt += x;
      }

      if (indx !== last) {
          years_txt += ', ';
      }
  });

  return years_txt;
};


export const getTagCondition = (tags) => {
  let match = tags.find((x) => {
    return x.startsWith('Condition:');
  });

  if (match) {
    match = match.split(':').slice(-1)[0];
  }

  return match;
};

export const getLargestSrc = (srcSet) => {
  let src = '';

  try {
    src = srcSet.split(',').slice(-1)[0].split(' ')[0];
  } catch (e) {
    console.error(e);
    src = '';
  }

  return src;
}


export const renderSpecTxt = (txt) => {
  if (!txt) { return null; }

  txt += ''; // convert to string

  const txt_arr = txt.split('·');

  const txt_mod = txt_arr.map((x, indx) => {
      if (!indx) { return x;} // don't color 0 index (that's the default spec text)

      return <SpecAlt alt_color_indx={indx}>{x}</SpecAlt>;
  });

  return txt_mod.flatMap((value, index, array) => {
      return array.length - 1 !== index ? [value, <SpecSep />] : value;
  });
};


// Note, allows available products to be first in the list, while keeping existing order of the rest
export const sortAvailableProducts = (product_arr = []) => {
  let sorted_arr = product_arr.slice(0);

  if (sorted_arr.length) {
    sorted_arr.sort((a, b) => {
      const a_isAvailable =  a.variants.filter(variant => variant.availableForSale).length > 0;
      const b_isAvailable =  b.variants.filter(variant => variant.availableForSale).length > 0;
     
      return b_isAvailable - a_isAvailable; // type coercion bool values, to get -1, 0, 1
    });
  }
  
  return sorted_arr;
}