import "@fontsource/dm-serif-display/latin-400.css";
import "@fontsource/space-mono/latin-400.css";
import './src/assets/styles/global.css';
import 'react-image-lightbox/style.css';

import * as React from "react"

import { StoreProvider } from "./src/context/store-context"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>{element}</StoreProvider>
)